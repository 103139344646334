import React from "react";
//STYLE
import { makeStyles, useTheme } from "@material-ui/core/styles";
//MUI CORE
import Grid from "@material-ui/core/Grid";
import bruno from "../assets/insta2.jpg";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//ANIMATION ON SCROLL
import AOS from "aos";
import "aos/dist/aos.css";

const skills = [
  {
    name: "React (JS and Native)",
    value: 95,
  },
  {
    name: "Java Script",
    value: 80,
  },
  {
    name: "Angular",
    value: 80,
  },
  {
    name: "CSS and HTML",
    value: 90,
  },
  {
    name: "Mainframe",
    value: 70,
  },
  {
    name: "Excel and VBA",
    value: 80,
  },
];

function About() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <Grid container className="container1" id="about" >
      <Grid
        item
        container
        alignItems="center"
        justify={matchesMD ? "center" : undefined}
        className="paddingHeader"
      >
        <Typography variant={matchesMD ? "h6" : "h3"}>ABOUT ME</Typography>
      </Grid>

      <Grid
        item
        container
        direction="row"
        justifyContent={matchesMD ? "center" : undefined}
      >
        {/* G R I D --- L E F T  */}
        <Grid
          item
          container
          direction="column"
          data-aos={matchesMD ? "fade-up" : "fade-right"}
          data-aos-duration="1000"
          alignItems={matchesMD ? "center" : undefined}
          className="paddingLeftGrid"
          lg
          md={8}
          sm={8}
          xs={10}
        >
          <Grid Item className="mt15">
            <Typography variant="subtitle2">
              Hello, I'am <span className={classes.subtitle1}>Bruno Vaz</span>{" "}
            </Typography>
          </Grid>

          <Grid
            item
            container
            className="mt15"
            justify={matchesMD ? "center" : undefined}
          >
            <Typography
              variant={matchesMD ? "body2" : "body1"}
              align={matchesMD ? "center" : undefined}
            >
              I'm a Freelancer Full Stack Developer with over 10 years of
              experience. I'm from Portugal. I code and create web elements
              for amazing people around the world. I like work with new people.
            </Typography>
          </Grid>

          <Grid
            item
            className="mt45"
            justify={matchesMD ? "center" : undefined}
          >
            <Typography
              variant="subtitle1"
              align={matchesMD ? "center" : undefined}
            >
              What is my skill level?
            </Typography>
          </Grid>

          <Grid
            item
            className="mt15"
            justify={matchesMD ? "center" : undefined}
          >
            <Typography
               variant={matchesMD ? "body2" : "body1"}
              align={matchesMD ? "center" : undefined}
            >
              I share with you some of my technical skills. 
              I hope my knowledge can add value to your project
        
            </Typography>
          </Grid>

          <Grid
            item
            container
            justifyContent={matchesMD ? "center" : undefined}
            alignItems="center"
          >
            {skills.map((row, index) => (
              <Grid
                item
                lg={5}
                md={12}
                sm={12}
                xs={12}
                key={index}
                className={matchesMD ? "marginSkills2" :  "marginSkills" }
              >
                <Typography className={ matchesMD ? classes.label2 :  classes.label}>{row.name}</Typography>
                <Grid item className={classes.linearGrid}>
                  <div className={classes.linearPadding}>
                    <LinearProgress variant="determinate" value={row.value} />
                  </div>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/* G R I D --- R I G H T  */}
        <Grid
          item
          container
          justifyContent={matchesMD ? "center" : "flex-end"}
          data-aos={matchesMD ? "fade-up" : "fade-left"}
          data-aos-duration="1000"
          className="paddingRightGrid"
          lg
        >
          <Grid item>
            <Grid item>
              <img src={bruno} alt="bruno" className={classes.image} />
            </Grid>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              className={classes.yellowCircle}
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <Grid item>
                <Typography
                  variant="h6"
                  align="center"
                  className={classes.colorWhite}
                >
                  10+ Years{" "}
                </Typography>
                <Typography
                  variant="subtitle2"
                  align="center"
                  className={classes.colorWhite}
                >
                  of experience
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  image: {
    height: "40em",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      height: "35em",
    },
  },
  subtitle1: {
    ...theme.typography.subtitle1,
  },
  label: {
    ...theme.typography.label,
  },
  label2: {
    ...theme.typography.label2,
  },
  linearGrid: {
    marginTop: "10px",
    border: "1px solid #333",
  },
  linearPadding: {
    padding: "5px",
  },
  yellowCircle: {
    position: "absolute",
    padding: "3em",
    backgroundColor: "#B3A26C",
    borderRadius: "15em",
    height: "15em",
    width: "15em",
    marginTop: "-12em",
    marginLeft: "-3em",
    zIndex: 99,
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      left: "50%",
      marginLeft: "-7.5em",
      marginTop: "-10em",
    },
  },
  colorWhite: {
    color: "#fff",
  },
}));

export default About;
