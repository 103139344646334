import React, { useState } from "react";
//STYLE
import { makeStyles, useTheme } from "@material-ui/core/styles";
//MUI CORE
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import ToolBar from "@material-ui/core/Toolbar";
//MUI ICONS
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from "@material-ui/icons/Home";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import SettingsIcon from "@material-ui/icons/Settings";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import BookIcon from "@material-ui/icons/Book";
import ContactsIcon from "@material-ui/icons/Contacts";
//IMAGES
import profile from "../assets/avatar.JPG";
import logo from "../assets/logo.jpg";

function SideBar() {
  const classes = useStyles();
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const menu = [
    { title: "Home", route: "#home" },
    { title: "About" , route: "#about" },
    { title: "Profile", route: "#profile"  },
    { title: "Services", route: "#services" },
    { title: "Portfólio", route: "#portfolio" },
    { title: "Contact", route: "#contact" },
  ];

  const sideHeader = (
    <React.Fragment>
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        className={classes.logo}
      >
      <Hidden mdDown>
        <Grid item>
          <img alt="logo" src={logo} style={{ height: "5em" }} />
      
        </Grid>
        </Hidden>
        <Hidden mdUp>
        <Grid item>

        <IconButton
        
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <CloseIcon className={classes.drawerIcon} />
        <Typography
            variant="body1"
            align="center"
            
            style={{  marginLeft: "0.5em" }}
          >
           Close
          </Typography>
      </IconButton>
        </Grid>
        </Hidden>
      </Grid>

      <Grid item container>
        <List className={classes.list}>
          {menu.map((text, index) => (
            <a
                        href={text.route}
                       className={classes.routes}
                       onClick={() => setOpenDrawer(!openDrawer)}
                      >
            <ListItem button key={index} className={classes.listItems}>
              <ListItemIcon>
                {text.title === "Home" && <HomeIcon />}
                {text.title === "Profile" && <PersonOutlineIcon />}
                {text.title === "Services" && <SettingsIcon />}
                {text.title === "Portfólio" && <LibraryBooksIcon />}
                {text.title === "About" && <BookIcon />}
                {text.title === "Contact" && <ContactsIcon />}
              </ListItemIcon>
              <ListItemText primary={text.title} />
            </ListItem>
            </a>
          ))}
        </List>
      </Grid>


      <Hidden mdDown>
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        className={classes.footer}
      >
        <Grid item>
          <Avatar alt="Mail" src={profile} className={classes.avatar} />
        </Grid>
        <Grid item style={{ marginLeft: "10px" }}>
          <Typography
            variant="body2"
            alignItems="center"
            className={classes.avatarTitle}
          >
            Send an e-mail:
          </Typography>
          <Typography variant="body2" alignItems="center">
            brunobvaz@gmail.com
          </Typography>
        </Grid>
      </Grid>

      </Hidden>
    </React.Fragment>
  );

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
      >
        {sideHeader}
      </SwipeableDrawer>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Hidden lgUp>
        <AppBar elevation={0} color="transparent">
          <ToolBar style={{}}>
            <IconButton
              className={classes.drawerIconContainer}
              onClick={() => setOpenDrawer(!openDrawer)}
              disableRipple
            >
              <MenuIcon className={classes.iconButton} />
            </IconButton>
          </ToolBar>
        </AppBar>
      </Hidden>

      {matches ? (
        drawer
      ) : (
        <Grid container className={classes.container}>
          {sideHeader}
        </Grid>
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "20em",
    minHeight: "100vh",
    backgroundColor: "#ffffff",
    position: "fixed",
    zIndex: 1000,
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "18em",
    },
  },
  drawerIconContainer: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  drawer: {
    width: "320px",
  },
  iconButton: {
    color: "#222",
    fontSize: 30,
  },
  logo: {
    height: "10em",
    borderBottom: "1px solid #c0c0c0",
    [theme.breakpoints.down("lg")]: {
      height: "8em",
    },
  },
  list: {
    padding: "2em",
  },
  listItems: {
    marginTop: "1em",
    paddingLeft: "3em",
    paddingRight: "3em",
    textTransform: "none",
  },
  footer: {
    borderTop: "1px solid #c0c0c0",
    [theme.breakpoints.down("lg")]: {
      padding: "1em",
    },
  },
  avatar: {
    width: "50px",
    height: "50px",
  },
  avatarTitle: {
    fontWeight: "600",
    color: "#333",
  },
  routes: {
    textDecoration: "none"
  }
}));

export default SideBar;
