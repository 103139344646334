import React from "react";
//STYLE
import { makeStyles, useTheme } from "@material-ui/core/styles";
//MUI CORE
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//ICONS
import WebIcon from "@material-ui/icons/Web";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import LanguageIcon from "@material-ui/icons/Language";
import DashboardIcon from "@material-ui/icons/Dashboard";
//IMAGE
import service from "../assets/service.jpeg";

const services = [
  {
    name: "Websites development and design",
    value: 95,
  },
  {
    name: "Mobile App development and design",
    value: 85,
  },
  {
    name: "Web business Consultancy",
    value: 90,
  },
  {
    name: "Excel, VBA, Enterprise Dashboard",
    value: 85,
  },
];

function Services() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container className="container1" id="services">
      <Grid
        item
        container
        direction="row"
        justifyContent={matchesMD ? "center" : undefined}
        alignItems={matchesMD ? "center" : undefined}
      >
        <Grid
          item
          container
          direction="column"
          className={matchesMD? "paddingCenterGrid2" : "paddingCenterGrid" } 
          lg
          md={8}
          sm={8}
          xs={10}
        >
          <Typography
            variant={matchesMD ? "h6" : "h3"}
            align={matchesMD ? "center" : undefined}
            paragraph
          >
            WHAT I DO{" "}
          </Typography>

          <Typography
            variant={matchesMD ? "body2" : "body1"}
            align={matchesMD ? "center" : undefined}
            style={{ width : matchesMD ? undefined : "60%" }}
            paragraph
          >
            I like to develop new solutions, improve implemented processes,
            discuss new projects and new business ideas.
          </Typography>

          <Grid
            item
            container
            direction={matchesMD ? "column" : undefined}
            alignItems="center"
            justify="space-between"
          >
            {services.map((row, index) => (
              <Grid
                item
                container
                alignItems="center"
                justify="center"
                key={index}
                className={classes.cards}
              >
                {index === 0 && <WebIcon className={classes.icon} />}
                {index === 1 && <PhoneIphoneIcon className={classes.icon} />}
                {index === 2 && <LanguageIcon className={classes.icon} />}
                {index === 3 && <DashboardIcon className={classes.icon} />}

                <Typography
                  variant="subtitle1"
                  align="center"
                  className={classes.color}
                >
                  {row.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* IMAGE SERVICOS */}

        <Grid item container alignItems="center" justify="center" className={classes.bgImage}>

        <Grid item >
          <Typography
            variant={matchesMD ? "h6" : "h3"}
            style={{ color: "#fff" }}
            align="center"
          >
            I have all tools to help you in your business or create your idea
          </Typography>
          </Grid>
        </Grid>


      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    backgroundColor: "#ffffff",
  },
  paddingHeader: {
    padding: "2em 0 2em 6em",
    [theme.breakpoints.down("md")]: {
      padding: "2em 5em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "1em 2em",
    },
  },
  paddingLeftGrid: {
    padding: "1em 6em 0 6em",
    [theme.breakpoints.down("md")]: {
      padding: "0 5em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0em 2em",
    },
  },
  cards: {
    marginTop: "2em",
    width: "15em",
    height: "15em",
    padding: "0.5em",
    border: "1px solid  #333",
    borderRadius: "5px",
    backgroundColor: "#333",
    "&:hover": {
      backgroundColor: "#f5f8fc",
    },
    "&:hover $color": {
      color: "#000",
    },
    "&:hover $icon": {
      color: "#333",
    },
    [theme.breakpoints.down("md")]: {
      width: "17em",
      height: "17em",
    },
  },

  color: {
    color: "#fff",
  },
  colorWhite: {
    color: "#fff",
  },
  icon: {
    color: "#fff",
    fontSize: 80,
  },
  bgImage: {
    margin: "10% 0 0 0",
    backgroundImage: `url(${service})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // backgroundAttachment: "fixed",
    height: "30em",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      //*   backgroundImage: `url(${mobileBackground})`, */
      backgroundAttachment: "inherit",
      height: "25em",
    },
    [theme.breakpoints.down("sm")]: {
      //*   backgroundImage: `url(${mobileBackground})`, */
      backgroundAttachment: "inherit",
      height: "20em",
    },
  },
}));

export default Services;
