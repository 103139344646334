import React from "react";
import Home from "./sections/Home"
import About from "./sections/About"
import Profile from "./sections/Profile"
import Services from "./sections/Services"
import Portfolio from "./sections/Portfolio"
import Contact from "./sections/Contact"


function LandingPage() {
 

  return (
<>
<Home />
<About />
<Profile />
<Services />
<Portfolio />
<Contact />

</>
  );
}


export default LandingPage;
