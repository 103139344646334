import { createMuiTheme } from "@material-ui/core/styles";

const bvBlue = "#0B72B9";
const bvOrange = "#FFBA60";
const bvGray = "#888888";
const bvGreen = "#515522";

const gold = "#B3A26C"
const lightGold = "#D9CBA2"


// light: será calculada com base em palette.primary.main,
// dark: será calculada com base em palette.primary.main,
// contrastText: será calculada para contrastar com palette.primary.main

export default createMuiTheme({
  palette: {
    common: {
      blue: `${bvBlue}`,
      orange: `${bvOrange}`,
      green: `${bvGreen}`,
      gray: `${bvGray}`,
    },
    primary: {
      main: `${bvBlue}`,
    },
    secondary: {
      main: `${bvOrange}`,
    },
  },
  typography: {
    h1: {
        fontSize: "75px",
        fontWeight: "700",
        lineHeight: "1.2",
        color: "#1a1a1a",
    },
    h2: {
      color: "#1a1a1a",
      fontSize: "55px",
      fontWeight: "700",
      lineHeight: "1.1"
      },
      h3: {
        color: "#1a1a1a",
        fontSize: "45px",
        fontWeight: "700",
        lineHeight: "1.0"
      },
    h4: {
      color: "#1a1a1a",
      fontSize: "40px",
      fontWeight: "700",
      lineHeight: "1.0"
    },
    h5: {
      color: "#1a1a1a",
      fontSize: "35px",
      fontWeight: "700",
      lineHeight: "1.0"
    },
    h6: {
      color: "#1a1a1a",
      fontSize: "30px",
      fontWeight: "700",
      lineHeight: "1.0"
    },

    subtitle1: {
      color: "#1a1a1a",
      fontWeight: "600",
      lineHeight: "1.5",
      fontSize: "22px",
    
    },
    subtitle2: {
      color: "#1a1a1a",
      fontWeight: "400",
      lineHeight: "1.5",
      fontSize: "22px",
    },

    body1: {
        fontFamily: "Poppins",
        fontSize: "18px",
        lineHeight: "30px",
        letterSpacing: "0px",
        fontWeight: "200",
        color: "#7e7e7e",
    },
    body2: {
      fontFamily: "Poppins",
      fontSize: "16px",
      letterSpacing: "0px",
      lineHeight: "25px",
      fontWeight: "200",
      color: "#7e7e7e",
  },
    label: {
      color: "#1a1a1a",
      fontSize: "16px",
      fontWeight: "600",
    },
    label2: {
      color: "#1a1a1a",
      fontSize: "14px",
      fontWeight: "600",
    },
    tab: {
      fontFamily: "Raleway",
      textTransform: "none",
      fontWeight: "700",
      fontSize: "1rem",
      color: "#FFF"
    },
    titleH1:{
      fontSize: "100px",
      fontFamily: "Italianno",
      color: "#333",
      fontWeight: 300,
    },
    titleH2:{
      fontSize: "70px",
      fontFamily: "Italianno",
      color: "#333",
      fontWeight: 300,
    },
    titleH3:{
      fontSize: "50px",
      fontFamily: "Italianno",
      color: "#333",
      fontWeight: 300,
    },
    estimate: {
      color: "#333",
      fontFamily: "Italianno",
      fontSize: "1rem",
      textTransform: "none",
    },
    learnButton: {
      borderColor: `${bvBlue}`,
      color: `${bvBlue}`,
      borderWidth: 2,
      textTransform: "none",
      borderRadius: 50,
      height: 45,
      width:145,
    }
  },
  overrides : {
 

    MuiAvatar :{
      root: {
        width: "240px",
        height: "240px",
        '@media (max-width: 600px)': {
          width: "160px",
          height: "160px",
        }
      },
 
    },

    MuiLinearProgress :{
      colorPrimary: {
        backgroundColor: lightGold
      },
      barColorPrimary:{
        backgroundColor: gold
      },
root : {
  height: 10,
}
 
    },
 

},



});