import React from "react";
//STYLE
import { makeStyles, useTheme } from "@material-ui/core/styles";
//MUI CORE
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Chip from "@material-ui/core/Chip";
//MUI ICONS
import LocalMallIcon from "@material-ui/icons/LocalMall";
import SchoolIcon from "@material-ui/icons/School";
import IconButton from "@material-ui/core/IconButton";
//ANIMATION ON SCROLL
import AOS from "aos";
import "aos/dist/aos.css";


function Profile() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <Grid container className="container2" id="profile">
    {/* PAGE TITLE */}
    <Grid item container 
        alignItems="center"
        justify={matchesMD ? "center" : undefined}
        className="paddingHeader"
    >
        <Grid item>
          <Typography variant={matchesMD ? "h6" : "h3"}>MY PROFILE</Typography>
        </Grid>
      </Grid>
{/* BASIC INFO */}
      <Grid
        item
        container
        alignItems={matchesSM ? "center" : undefined}
        justifyContent="space-between"
        direction={matchesSM ? "column" : "row"}
        className="paddingCenterGrid"
      >
        {info.map((row, index) => (
          <Grid
            item
            container
            key={index}
            direction="column"
            alignItems={matchesMD ? "center" : undefined}
            data-aos="fade-up"
            data-aos-duration="1000"
            className="mt"
           lg={3}
           md={3}
          >
            <Grid
              Item
              style={{ width: "9em"}}
            >
              <Typography
                className={classes.borderB}
                variant="subtitle1"
                paragraph
                align={matchesSM ? "center" : undefined}
              >
                {row.title}
              </Typography>
            </Grid>

            {row.title === "Languages" ? (
              <Grid Item  >
                <Typography variant={matchesMD ? "body2" : "body1"}>
                  <span className={classes.body2}>
                    {row.d1} {row.d2}
                  </span>{" "}
                </Typography>
                <Typography variant={matchesMD ? "body2" : "body1"}>
                  <span className={classes.body2}>
                    {row.d3} {row.d4}
                  </span>{" "}
                </Typography>
              </Grid>
            ) : (
              <Grid Item  >
                <Typography variant={matchesMD ? "body2" : "body1"}>
                  {row.t1} <span className={classes.body2}>{row.d1}</span>{" "}
                </Typography>
                <Typography variant={matchesMD ? "body2" : "body1"}>
                  {row.t2} <span className={classes.body2}>{row.d2}</span>{" "}
                </Typography>
                <Typography variant={matchesMD ? "body2" : "body1"}>
                  {row.t3} <span className={classes.body2}>{row.d3}</span>{" "}
                </Typography>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>


{/* EXPERIENCE */}
      <Grid item container direction="row">
        <Grid
          item
          container
          direction="column"
          justifyContent={matchesMD ? "center" : undefined}
          alignItems={matchesMD ? "center" : undefined}
          data-aos={matchesMD ? "fade-up" : "fade-right"}
          data-aos-duration="1000"
          className={classes.paddingLeftGrid}
          lg
          md={6}
         
        >

          <Grid item className={classes.mt45}>
            <Typography variant="subtitle1">Professional Experience</Typography>
          </Grid>

          <Grid item  container justifyContent={matchesMD ? "center" : undefined }
          alignItems={matchesMD ? "center" : undefined}
       
          >
            {experience.map((row, index) => (
              <Grid
                item
                lg={8}
                md={8}
                sm={8}
                xs={8}
                key={index}
                className={classes.marginExp}
                
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  className={classes.iconB}
                >
                  <Grid item>
                    <IconButton style={{ backgroundColor: "#c0c0c0" }}>
                      <LocalMallIcon />
                    </IconButton>
                  </Grid>
                  <Grid item  style={{  marginLeft: matchesSM ? "1em" : "2em" }}>
                    <Chip label={`${row.start} - ${row.end}`} />
                  </Grid>
                </Grid>
                <Typography className={classes.label} >{row.name}</Typography>
                <Typography  variant={matchesMD ? "body2" : "body1"}>{row.value}</Typography>
              </Grid>
            ))}
          </Grid>
         
        </Grid>


        <Grid
          item
          container
          direction="column"
          justifyContent={matchesMD ? "center" : undefined}
          alignItems={matchesMD ? "center" : undefined}
          data-aos={matchesMD ? "fade-up" : "fade-right"}
          data-aos-duration="1000"
          className={classes.paddingLeftGrid}
          lg
          md={6}
         
        >

          <Grid item className={classes.mt45}>
            <Typography variant="subtitle1">Education and Certification</Typography>
          </Grid>

          <Grid item  container justifyContent={matchesMD ? "center" : undefined }
          alignItems={matchesMD ? "center" : undefined}
       
          >
            {education.map((row, index) => (
              <Grid
                item
                lg={8}
                md={8}
                sm={8}
                xs={8}
                key={index}
                className={classes.marginExp}
                
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  className={classes.iconB}
                >
                  <Grid item>
                    <IconButton style={{ backgroundColor: "#c0c0c0" }}>
                    <SchoolIcon />
                    </IconButton>
                  </Grid>
                  <Grid item  style={{  marginLeft: matchesSM ? "1em" : "2em" }}>
                    <Chip label={`${row.start} - ${row.end}`} />
                  </Grid>
                </Grid>
                <Typography className={classes.label} >{row.name}</Typography>
                <Typography  variant={matchesMD ? "body2" : "body1"}>{row.value}</Typography>
              </Grid>
            ))}
          </Grid>
         
        </Grid>



      </Grid>
    </Grid>
  );
}





const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    backgroundColor: "#f5f8fc",
    padding: "2em 0",
  },

  paddingLeftGrid: {
    padding: "0 0 0 6em",
    [theme.breakpoints.down("md")]: {
      padding: "0 0 0 0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 0 0",
    },
  },
  marginTopGrid: {
    marginTop: 0,
    [theme.breakpoints.down("md")]: {
      marginTop: "1em",
    },
  },
  subtitle1: {
    ...theme.typography.subtitle1,
  },
  body2: {
    fontFamily: "Poppins",
    fontSize: "18px",
    letterSpacing: "0px",
    fontWeight: "600",
    color: "#B3A26C",
  },
  label: {
    ...theme.typography.label,
  },
  marginExp: {
    marginRight: "2em",
    marginTop: "50px",
    padding: "1em",
    borderLeft: "1px solid #333",
    width: "20em",
    [theme.breakpoints.down("md")]: {
      marginRight: "0em",
 
    },
  },
  mt45: {
    marginTop: "45px",
  },
  borderB: {
    borderBottom: "2px dashed #c0c0c0",
  },
  iconB: {
    marginLeft: "-2.5em",
    marginTop: "-2.5em",
  },
}));


const info = [
  {
    title: "Personal Info",
    t1: "First Name:",
    t2: "Last Name:",
    t3: "Age:",
    d1: "Bruno",
    d2: "Vaz",
    d3: "36",
    d4: "",
  },
  {
    title: "Address Info",
    t1: "Nationality:",
    t2: "Address:",
    t3: "Place of birth:",
    d1: "Portuguese",
    d2: "Porto",
    d3: " Paris",
    d4: "",
  },
  {
    title: "Languages",
    t1: "",
    t2: "",
    t3: "",
    d1: "French,",
    d2: " English,",
    d3: " Portuguese,",
    d4: " Spanish",
  },
];

const experience = [
  {
    name: "Accenture",
    value: "Backend Developer / Senior Developer",
    start: "2010",
    end: "2015",
  },
  {
    name: "Accenture",
    value: "Analyst / Team Lead",
    start: "2015",
    end: "2019",
  },
  {
    name: "Freelancer",
    value: "Full Stack Developer",
    start: "2018",
    end: "Present",
  },
  {
    name: "Natixis",
    value: "Team Lead",
    start: "2019",
    end: "Present",
  },
];

const education = [
  {
    name: "Engineering master degree",
    value: "FCT - Nova Lisbon University",
    start: "2005",
    end: "2010",
  },
  {
    name: "Tester Qualification",
    value: "ISTQB - Expert Level",
    start: "2015",
    end: "2015",
  },
  {
    name: "Scrum master",
    value: "Agile lidership Scrum.org",
    start: "2019",
    end: "2019",
  },
  {
    name: "Operation Toolkit",
    value: "Porto Business School",
    start: "2021",
    end: "Present",
  },
];


export default Profile;
