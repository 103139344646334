import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import bruno from "../assets/bruno.jpg";
import projeto1 from "../assets/projeto1.jpg";
import projeto2 from "../assets/projeto2.jpg";
import projeto3 from "../assets/projeto3.jpg";
import projeto4 from "../assets/projeto4.jpg";

import WebIcon from "@material-ui/icons/Web";

import ReactTooltip from "react-tooltip";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const projects = [
  {
    name: "Project1",
    foto: bruno,
  },
  {
    name: "Project2",
    foto: bruno,
  },
  {
    name: "Project3",
    foto: bruno,
  },
  {
    name: "Project4",
    foto: bruno,
  },
];

function Portfolio(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

 

var settings = {
    dots: true,
    arrow: true,
    //prevArrow: <SamplePrevArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          arrow: false,
          autoplay: false,
          draggable: true,
          speed: 300,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          arrow: false,
          autoplay: false,
          draggable: true,
          speed: 300,
          dots: true,
        },
      },
    ],
};

  return (
    <Grid container className="container3" id="portfolio">
      <Grid
    
        container
      
        justifyContent={matchesMD ? "center" : "center"}
      >
        <Grid
          item
          container
          direction="column"
          className={matchesMD? "paddingCenterGrid2" : "paddingCenterGrid" } 
          lg
          md={8}
          sm={8}
          xs={10}
         
        >
          <Typography
            variant={matchesMD ? "h6" : "h3"}
            align={matchesMD ? "center" : undefined}
            paragraph
          >
            WORK IN PROGRESS{" "}
          </Typography>

          <Typography
            variant={matchesMD ? "body2" : "body1"}
            align={matchesMD ? "center" : undefined}
            style={{ width : matchesMD ? undefined : "60%" }}
            paragraph
            
          >
            I share with you my ongoing projects. Help people is a simple equation: your success is my success
          </Typography>
        </Grid>
        {/* CAROUSEL */}
        <div
            className="container"
          >
          <Slider {...settings}>
           
           
            <div className="image">
              <a>
                <img
                  className={classes.image}
                  style={{ backgroundImage: `url(${projeto2})`, height: "85%" }}
                />
                <Grid style={{ border: "5px solid #ffffff" }}>
                  <Typography variant="body1" align="center">
                    MENUMENT • FOOD AND HEALTH
                  </Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    Mobile APP development
                  </Typography>
                </Grid>
              </a>
            </div>


            <div className="image">
              <a>
                <img
                  className={classes.image}
                  style={{ backgroundImage: `url(${projeto3})`, height: "85%" }}
                />
                <Grid style={{ border: "5px solid #ffffff" }}>
                  <Typography variant="body1" align="center">
                    PUREXERCISE • GYM PLATFORM
                  </Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    Website development
                  </Typography>
                </Grid>
              </a>
            </div>


            <div className="image">
              <a>
                <img
                  className={classes.image}
                  style={{ backgroundImage: `url(${projeto1})`, height: "85%" }}
                />
                <Grid style={{ border: "5px solid #ffffff" }}>
                  <Typography variant="body1" align="center">
                    VALE DE MERÓDIOS • DIGITAL FARM
                  </Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    Website development
                  </Typography>
                </Grid>
              </a>
            </div>
            <div className="image">
              <a>
                <img
                  className={classes.image}
                  style={{ backgroundImage: `url(${projeto4})`, height: "85%" }}
                />
                <Grid style={{ border: "5px solid #ffffff" }}>
                  <Typography variant="body1" align="center">
                    VASTAMONTRA • DIGITAL TRANSFORMATION
                  </Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    Consultancy and Development
                  </Typography>
                </Grid>
              </a>
            </div>


          </Slider>
      </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    backgroundColor: "#FFFFFF",
  },
  paddingTitle: {
    padding: "2em 0 2em 6em",
    [theme.breakpoints.down("md")]: {
      padding: "2em 5em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "1em 2em",
    },
  },
  paddingPage: {
    padding: "1em 6em 0 6em",
    [theme.breakpoints.down("md")]: {
      padding: "0 5em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0em 2em",
    },
  },
  portfolio: {
    width: "100%",
    height: "auto",
    clear: "both",
    float: "left",
    paddingTop: "60px",
  },
  image: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default Portfolio;
