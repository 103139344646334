import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
//MUI ICONS
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import InstagramIcon from '@material-ui/icons/Instagram';

function Contact() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container className="container1" id="contact">
      <Grid item container direction="row" justifyContent={matchesMD ? "center" : undefined} lg>
   
       
          <Grid
          item
          container
          direction="column"
          className={matchesMD? "paddingCenterGrid2" : "paddingCenterGrid" } 
          lg
          md={8}
          sm={8}
          xs={10}
        >
          <Typography
            variant={matchesMD ? "h6" : "h3"}
            align={matchesMD ? "center" : undefined}
            paragraph
          >
            CONTACT ME{" "}
          </Typography>

          <Typography
            variant={matchesMD ? "body2" : "body1"}
            align={matchesMD ? "center" : undefined}
            style={{ width : matchesMD ? undefined : "60%" }}
             
          >
               I' am available to receive your e-mails, your calls and your
            Whatsapp messages.
          </Typography>
        </Grid>
    

        <Grid
          item
          container
          className={classes.paddingLeftGrid}
          justify={matchesMD ? "center" : "space-between"}
          
        >
          <Grid
            item
            style={{
              backgroundColor: "#ffffff",
              width: "30em",
              height: "30em",
              padding: "1em",
            }}
          >
            <TextField
              label="Name *"
              id="name"
              fullWidth
              //       value={name}
              //       onChange={(event) => setName(event.target.value)}
              className={`${classes.mt15} ${classes.root}`}
            />

            <TextField
              label="Email *"
              id="email"
              fullWidth
              //       value={name}
              //       onChange={(event) => setName(event.target.value)}
              className={`${classes.mt15} ${classes.root}`}
            />

            <TextField
              label="Message *"
              id="message"
              fullWidth
              //       value={name}
              //       onChange={(event) => setName(event.target.value)}
              className={`${classes.mt15} ${classes.root}`}
              multiline
              rows={10}
            />

            <Button
              variant="contained"
              //   onClick={onConfirm}
              className={`${classes.mt30} ${classes.button}`}
              fullWidth={matchesMD ? true :  undefined}
            >
              Send message
            </Button>
          </Grid>

          <Grid item style={{ width: "30em", height: "30em", marginTop: matchesMD? "2em" : undefined }}>
            <Grid item container direction="column" >
              <Grid
                item
                container
                style={{
                  backgroundColor: "#f5f8fc",
                  border: "1px solid #222",
                  height: "9em",
                  padding: "1em",
                }}
                alignItems="center"
              >
                <Grid item>
                  <PhoneIcon style={{ fontSize: "50px" }} />
                </Grid>

                <Grid item style={{ marginLeft: "1em" }}>
                  <Typography variant="subtitle1">Phone / Whatsapp</Typography>
                  <Typography variant="body1">+351 964233203</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                style={{
                  backgroundColor: "#f5f8fc",
                  border: "1px solid #222",
                  height: "9em",
                  padding: "1em",
                  marginTop: "1.5em",
                }}
                alignItems="center"
              >
                                <Grid item>
                  <MailIcon style={{ fontSize: "50px" }} />
                </Grid>

                <Grid item style={{ marginLeft: "1em" }}>
                  <Typography variant="subtitle1">Email</Typography>
                  <Typography variant="body1">brunobvaz@gmail.com</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                style={{
                  backgroundColor: "#f5f8fc",
                  border: "1px solid #222",
                  height: "9em",
                  padding: "1em",
                  marginTop: "1.5em",
                }}
                alignItems="center"
              >
                                       <Grid item>
                  <InstagramIcon style={{ fontSize: "50px" }} />
                </Grid>

                <Grid item style={{ marginLeft: "1em" }}>
                  <Typography variant="subtitle1">Instagram</Typography>
                  <Typography variant="body1">bruno_b_vaz</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    backgroundColor: "#f5f8fc",
  },
  paddingHeader: {
    padding: "2em 0 2em 6em",
    [theme.breakpoints.down("md")]: {
      padding: "2em 5em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "1em 2em",
    },
  },
  paddingLeftGrid: {
    padding: "1em 6em 0 6em",
    [theme.breakpoints.down("md")]: {
      padding: "0 5em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0em 2em",
    },
  },
  root: {
    "& label.Mui-focused": {
      color: "#333",
    },
  },
  mt15: {
    marginTop: "15px",
  },
  mt30: {
    marginTop: "30px",
  },
}));

export default Contact;
