import React from "react";
//STYLE
import { makeStyles , useTheme } from "@material-ui/core/styles";
//MUI CORE
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import profile from "../assets/profile.JPG";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//MUI ICONS
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

function Home() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      className={classes.container}
      justifyContent="center"
      alignItems="center"
      id="home"
    >
      <Grid item container direction="column" justify="center" alignItems="center" lg={4} md={6} sm={8} xs={10}>
        <Grid item>
          <Avatar
            alt="Bruno Vaz"
            src={profile}
          />
        </Grid>
        <Grid item>
          <Typography variant={matchesMD ? "h4" : "h1"} align="center" className={classes.name}>
            BRUNO VAZ
          </Typography>
          <Typography variant={matchesMD ? "body2" : "body1"} align="center">
            I'am a Full Stack Developer with over 10 years of professional experience in this field
          </Typography>
        </Grid>
        <Grid item>

          <InstagramIcon className={classes.icon} />
          <FacebookIcon  className={classes.icon} />
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    backgroundColor: "#f5f8fc"
  },
  name: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
  },
  icon : {
    color: "#7e7e7e",
    padding: "5px"
  },
}));

export default Home;
