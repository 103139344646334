import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
//import { Link } from "react-router-dom";
import theme from "./style/Theme.js";
import LandingPage from "./LandingPage";
import SideBar from "./componentes/SideBar.js";
import Grid from "@material-ui/core/Grid";
import "./App.css";

function App(props) {

  

  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="row">
        <Grid
          item
          container
          lg={2}
          md={2}
          style={{
            boxShadow:
              "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
          }}
        >
          <SideBar />
        </Grid>
        <Grid item container lg={10} md={12} sm={12} xs={12}>
          <LandingPage />
        </Grid>



  


      </Grid>
    </ThemeProvider>
  );
}

export default App;
